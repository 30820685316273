import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Box, Paper, Skeleton, Typography } from "@mui/material";
import theme from "../../util/theme";
import axiosInstance from "util/axiosInstance";
import { connect } from "react-redux";
import moment from "moment";
import Slider from "react-slick";
import { truncate } from "lodash";
import { useNavigate } from "react-router-dom";
import { AMAZON_S3 } from "apiConfig";

function UpcomingCompetition(props) {
  const navigate = useNavigate();
  const [textLength, setTextLength] = useState(80);
  const [announcementLoading, setAnnouncementLoading] = useState(false);
  const [announcement, setAnnouncement] = useState([]);

  useEffect(() => {
    handleWidthChange();
  }, []);
  useEffect(() => {
    if (props?.branchDetail?.id) {
      fetchAnnouncement();
    }
  }, [props?.branchDetail]);
  useEffect(() => {
    window.addEventListener("resize", handleWidthChange);
    return () => {
      window.removeEventListener("resize", handleWidthChange);
    };
  }, []);
  var settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    centerMode: true,
    centerPadding: "16px 0px 0px 0px",
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 10000,
  };
  const handleWidthChange = () => {
    const currWidth = window.innerWidth;
    if (currWidth > 450) {
      setTextLength(80);
    } else if (currWidth <= 450 && currWidth > 400) {
      setTextLength(65);
    } else if (currWidth <= 400 && currWidth > 350) {
      setTextLength(50);
    } else if (currWidth <= 350) {
      setTextLength(35);
    }
  };
  const fetchAnnouncement = () => {
    setAnnouncementLoading(true);
    let params = {
      page: 1,
      page_size: 10,
      acad_session: props?.branchDetail?.id,
      is_flash_event: true,
    };
    axiosInstance
      .get(`announcement/v2/announcement/`, { params: params })
      .then((res) => {
        setAnnouncement(res?.data?.results);
      })
      .catch((err) => {
        console.log("Announcement fetch error", err);
      })
      .finally(() => {
        setAnnouncementLoading(false);
      });
  };

  return (
    <>
      {announcementLoading ? (
        <Skeleton sx={{ mx: 2, mt: 1, height: 200, borderRadius: 4 }} />
      ) : announcement?.length > 0 ? (
        <Box
          sx={{
            py: 2,
          }}
          className="comp-slick"
        >
          <Slider {...settings}>
            {announcement?.map((item, ind) => (
              <Box sx={{ pl: announcement?.length > 1 ? 2 : 0 }} key={ind}>
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: 4,
                  }}
                  onClick={() => {
                    if (props?.userLevel === 13) {
                      navigate("/announcements?flash=true", {
                        state: { announcement_id: item?.id },
                      });
                    } else if ([8, 10, 11]?.includes(props?.userLevel)) {
                      navigate("/announcements-teacher?flash=true", {
                        state: { announcement_id: item?.id },
                      });
                    }
                  }}
                >
                  {item?.flash_img?.length > 0 ? (
                    <Box
                      sx={{
                        borderRadius: 4,
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={`${AMAZON_S3.ERP_BUCKET}/announcement/${item?.flash_img[0]}`}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          aspectRatio: "2 / 1",
                        }}
                        alt="Important Event"
                      />
                    </Box>
                  ) : null}
                </Paper>
              </Box>
            ))}
          </Slider>
        </Box>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  current_acad_year: state.user?.currentAcadYear,
  sessionYear: state.user?.branch?.session_year,
  branchDetail: state.user?.branch,
  userLevel: state.user?.userData?.user_level,
});

export default connect(mapStateToProps)(UpcomingCompetition);
